// Resets
section:focus {
    outline: none !important;
}

button {
    &:focus, &:active {
        outline: none;
    }
}

// Preloader 
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 99;
}

#status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    // centers the loading animation horizontally on the screen 
    top: 50%;
    // centers the loading animation vertically on the screen 
    background-image: url(../images/loaders/rings.svg);
    background-size: 80px 80px;
    // path to loading animation 
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
    // width and height divided by two 
}

// Back to top button
#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}

#backtotop.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: $secondary;
}

#backtotop a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $medium-grey;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #backtotop a {
    outline: none;
    color: #fff;
}

#backtotop a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}


//Helpers
.is-disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default !important;
}

.is-hidden {
    display: none !important;
}

.stuck {
    position:fixed !important;
    top: 0 !important;
    z-index: 2 !important;
}

.light-text {
    color: $white !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-10p {
    margin-bottom: 8%;
}


.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.disable-mt-40 {
    margin-top: -20px !important;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-10p {
    margin-top: 8%;
}

.ml-100 {
    margin-left: 60em;
}

.ml-50 {
    margin-left: 30em;
}

.ml-30 {
    margin-left: 30px;
}

.ml-10 {
    margin-left: 10px;
}

.huge-pb {
    padding-bottom: 100px;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

//Input placeholders
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
}


.background-iburg {
    /* The image used */
    background-image: url(../images/pictures/iburg.jpg);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.transparent-bg {
    background: transparent;
}

.text-format {
    font-size: 1.5rem;
    font-weight: 500;
}

.text-align-left {
    flex: 0 0 70%;
}


.text-align-right {
    flex: 30% 0 0;
}

.text-align-left-icon {
    flex: 0 0 67%;
}

.text-align-left-icon-re {
    flex: 0 0 60%;
}

.text-align-right-icon {
    flex: 33% 0 0;
}

.text-align-right-icon-re {
    flex: 40% 0 0;
}

.re-small {
    height: 29em;
    float: right;
}

.text-with-picture-right {
    display: flex;
}

.picture-left {
    float: left;
    margin-right: 10px;
}

.text-with-picture-left {
    display: flex;
}

.cite-format {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.cite-format-2 {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
}

.color-highlight {
    color: orange;
}

a {
    color: orange;
}

.new-line {
    padding-top: 2%;
}

.orange-line {
    background-color: orange;
    height: .5%;
}

.align-bottom {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.detail-icon img {
    height: 120px;
    margin-right: 40px;
}

.detail-icon-idea img {
    height: 20em;
    margin-right: 2.5vw;
}

ul li::marker, 
ol li::marker {
  color: orange;
}

.blue {
    background-color: aqua;
}

.red {
    background-color: red;
}

.green {
    background-color: green;
}

.agb {
    margin-top: 10px;
    font-weight: 500;
}


/* Standardstil für Links */
nav a {
    position: relative;
    text-decoration: none;
    color: black;
    padding: 5px 0;
  }
  
  /* Unterstrich beim Hover */
  nav a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: orange; /* Farbe des Unterstrichs */
    transition: width 0.3s ease;
  }
  
  /* Hover-Effekt */
  nav a:hover::after {
    width: 100%;
  }
  
  /* Unterstrich für aktive Sektion */
  nav a.active::after {
    width: 100%;
  }



  /* For Mobile */
@media screen and (max-width: 540px) {
    .cite-format {
        font-size: 1rem;
        margin-left: 5%;
        margin-right: 5%;
    }
    .cite-format-2 {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        margin-left: 3%;
        margin-right: 3%;
    }
    .text-format {
        font-size: 1rem;
    }
    .mt-40 {
        margin-top: 1px;
    }
    .ml-100-mobile {
        margin-left: 0;
    }

    .mt-10p {
        margin-top: 5%;
    }

    .text-with-picture-left {
        display: block; /* Ermöglicht flexibles Verhalten */
        position: relative; /* Ermöglicht genauere Platzierung des Bildes */
    }
    
    .text-with-picture-left img {
        float: right; /* Bild bleibt rechts */
        margin-left: 1rem; /* Abstand zum Text links */
        max-width: 40%; /* Begrenzung der Bildbreite */
        height: auto; /* Dynamische Höhe */
    }


    .text-with-picture-left .media-content {
        overflow: hidden; /* Verhindert Überlaufprobleme mit dem Text */
    }



    .text-format {
        font-size: 1rem; /* Kleinere Schriftgröße für mobile Geräte */
    }

    .not-show { 
        display: none; 
    }

    .detail-icon img {
        height: 20vw;
    }

    .opacity-zero {
        opacity: 0;
    }

    .mobile-re {
        margin-top: 0 !important;
    }

}



//   /* For Mobile */
// @media screen and (max-width: 540px) {
//     .cite-format {
//         font-size: 1rem;
//         margin-left: 5%;
//         margin-right: 5%;
//       }
//       .cite-format-2 {
//         font-size: 1rem;
//         font-weight: 500;
//         text-align: center;
//         margin-left: 3%;
//         margin-right: 3%;
//     }
//     .text-format {
//         font-size: 1rem;
//     }
//     .mt-40 {
//         margin-top: 1px;
//     }

//     .ml-100 {
//         margin-left: 1em;
//     }

        
//     .text-with-picture-left {
//         display: inline-block;
//     }
    
//     .text-with-picture-left img {
//         float:left;
//         margin-right:1rem;
//         padding-right: 5vw;
//         max-width: 100% !important;
//         height: auto !important;
//         background-color: red;
//     }
// }
