/*! _cards.scss v1.0.0 | Commercial License | built on top of bulma.io/Bulmax */

/* ==========================================================================
Cards and Card content styles
========================================================================== */

// Feature Card
.feature-card {
    width: 375px;
    height: 410px;
    background-color: #fff;
    border-radius: 8%;
    margin: 0 auto;
    .card-title h4 {
        font-family: 'Fira Sans', sans-serif;
        padding-top: 25px;
        font-size: 1.2rem;
        font-weight: 600;
        color: $blue-grey;
    }
    .card-icon img {
        height: 110px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .card-text {
        padding: 0 40px;
        font-size: 1.1rem;
        font-weight: 300;
        p {
            color: $muted-grey;
        }
    }
    &.is-bordered {
        border: 1px solid $fade-grey;
    }
}

// Flex Card
.flex-card {
    position: relative;
    background-color: white;
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    &.raised {
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    }
    .tabs {
        padding: 15px 0.7rem;
    }
    .navtab-content {
        min-height: 190px;
        p {
            padding: 0 0.8rem 20px;
        }
    }
    .navigation-tabs {
        &.outlined-pills .tabs.tabs-header {
            &.primary {
                background-color: $primary;
            }
            &.secondary {
                background-color: $secondary;
            }
            &.accent {
                background-color: $accent;
            }
            ul li a {
                color: $grey-white;
            }  
            ul li.is-active a {
                color: $white;
                border: 1px solid $white;
                border-bottom-color: $white !important;
            }
        }
    }

}

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .feature-card {
        width: 80vw;
        height: 90vw;
        background-color: #fff;
        border-radius: 8%;
        margin: 0 auto;
        .card-title h4 {
            font-family: 'Fira Sans', sans-serif;
            padding-top: 25px;
            font-size: 1.2rem;
            font-weight: 600;
            color: $blue-grey;
        }
        .card-icon img {
            height: 20vw;
            margin-top: 20px;
            margin-bottom: 5px;
        }
        .card-text {
            padding: 0 40px;
            font-size: 1rem;
            font-weight: 300;
            p {
                color: $muted-grey;
            }
        }
        &.is-bordered {
            border: 1px solid $fade-grey;
        }
    }
  }


  @media screen and (max-width: 431px) {
    .feature-card {
        width: 85vw;
        height: 78vw;
    }
  }


  @media screen and (max-width: 414px) {
    .feature-card {
        width: 85vw;
        height: 85vw;
    }
  }


  
  @media screen and (max-width: 400px) {
    .feature-card {
        width: 85vw;
        height: 95vw;
    }

    .card-text {
        margin-top: 2vw;
    }
  }

  @media screen and (max-width: 382px)  {
    .feature-card {
        width: 85vw;
        height: 100vw;
    }
  }

  @media screen and (max-width: 376px)  {
    .feature-card {
        width: 90vw;
        height: 95vw;
    }
  }


  @media screen and (max-width: 361px)  {
    .feature-card {
        width: 93vw;
        height: 100vw;
    }
  }

  @media screen and (max-width: 346px)  {
    .feature-card {
        width: 93vw;
        height: 110vw;
    }
  }

  
  @media screen and (max-width: 323px) {
    .feature-card {
        width: 90vw;
        height: 120vw;
    }
  }

  @media screen and (max-width: 310px) {
    .feature-card {
        width: 90vw;
        height: 135vw;
    }
  }

  @media screen and (max-width: 297px) {
    .feature-card {
        width: 90vw;
        height: 140vw;
    }
  }