/* ==========================================================================
Fresh Footer
========================================================================== */

footer.footer-dark {
    background: $blue-grey;
    color: $white;
    .footer-logo {
        img {
            height: 8em;
        }
    }
    .footer-column {
        margin-right: 2em;

        .footer-header h3 {
            font-weight:500;
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
        ul.link-list {
            line-height: 40px;
            font-size: 1rem;
            a {
                color: $light-blue-grey;
                font-weight: 400;
                transition: all 0.5s;
            }
            :hover {
                color: $smoke-white;
            }
        }
        .level-item .icon {
            color: $secondary;
            transition: all 0.5s;
            :hover {
                color: $smoke-white;
            }
        }
    }
}

.right {
    
    float: right;
    margin-left: 15%;
}

.disable-flex-grow {
    flex-grow: 0;
}

.storyset {
    padding-top: 2.5em;
    float: left;
}

.white {
    color: $light-blue-grey;
    font-weight: 400;
    transition: all 0.5s;
&:hover {
        color: $smoke-white;
    }
}

@media screen and (max-width: 540px) {
    .footer {
        padding: 2rem 1rem !important;
    }
    
    .footer a {
        display: inline-block !important;
        margin: 0.5rem !important;
        text-align: center !important;
        color: #333 !important;
    }

    .storyset {
        padding-top: 3vw;
        padding-left: 25vw;
        padding-right: 20vw;
    }

    footer.footer-dark {
        color: white;
        height: 40vw;
    }

    ul.link-list {
        line-height: 0px !important;
    }

    .right {
        float: right;
        margin-left: 3%;
    }

    .disable-flex-grow {
        flex-grow: 1;
    }

    .delete-margin-right {
        margin-right: 0rem;
    }

}


@media screen and (max-width: 389px) {

    .storyset {
        padding-top: 3vw;
        padding-left: 20vw;
        padding-right: 20vw;
    }

    .right {
        float: right;
        margin-left: 0%;
    }
}


@media screen and (max-width: 353px) {

    .storyset {
        padding-top: 3vw;
        padding-left: 15vw;
        padding-right: 15vw;
        padding-bottom: 5vw;
    }

    .right {
        text-align: center;
        float:none;
        margin-left: 0%;
        padding: 0.15rem !important;
    }
}