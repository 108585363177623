/* ==========================================================================
Hero styles
========================================================================== */

.hero-body {
    padding-top: 18rem;
    padding-bottom: 0rem;
    .title, .subtitle {
        color: $white;
        font-family: 'Fira Sans', sans-serif;
        font-size: 6rem;
        margin-left: 5%;
    }
    .title {
        &.is-bold {
            font-weight: 575;
        }
    }
    .subtitle {
        &.is-muted {
            color: $white;
        }
    }
}

.hero-foot {
    img.partner-logo {
        height: 70px;
    }
}

.do-not-show-desktop {
    display:none;
}

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .title {
        &.is-bold {
            font-weight: 575;
            font-size: 4rem;
            text-align: left;
            padding-top: 10%;
        }
    }

    .do-not-show-mobile {
        display:none;
    }

    .do-not-show-desktop {
        display:block;
    }
}

/* For Mobile */
@media screen and (max-width: 400px) {
    .title {
        &.is-bold {
            font-weight: 575;
            font-size: 3.25rem;
            text-align: left;
            padding-top: 10%;
        }
    }
}

/* For Mobile */
@media screen and (max-width: 345px) and (max-height: 900px) {
    .title {
        &.is-bold {
            font-weight: 575;
            font-size: 3rem;
            text-align: left;
            padding-top: 10%;
        }
    }
}